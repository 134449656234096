
import React from 'react';
import { Grid, Box, Icon} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import {GridToolbarColumnsButton,  GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid';
import axios from "axios";
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';


import CommentModal from '../../../../../components/CommentModal/CommentModal';
import AddFlagModal from '../../../../../components/AddFlagModal/AddFlagModal';
import SeeFlagModal from '../../../../../components/SeeFlagModal/SeeFlagModal';

function IncidenceEditTab({entity, updatedNew, setUpdatedNew}) {
  
  const [IRefs, setIRefs] = React.useState([])
  const [selected, setSelected] = React.useState([])
  const [editMode, setEditMode] = React.useState(false)
  const [maxId, setMaxId] = React.useState(0);
  const [origCopy, setOrigCopy] = React.useState([])
  const [likedMap, setLikedMap] = React.useState(new Map())
  const [liked, setLiked] = React.useState(false)
  const [seeFlagOpen, setSeeFlagOpen] = React.useState("")

  const handleLike = async (id) => {
    if(likedMap.has(String(id))) {

      await axios.delete(`https://api.clinicalphenomics.org/raiting/CRI/${String(likedMap.get(String(id)))}`, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })



    }
    else {
      const payload = {
        type: "Reference",
        liked: true,
        type_id: id
      }
      await axios.post(`https://api.clinicalphenomics.org/raiting/CRI`, payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }



    setLiked(!liked)
  }

  
  React.useEffect(() => {
    const getLikes = async () => {
      if(IRefs.length == 0) {
        return;
      }

      if(editMode) {
        return;
      }


      const ids = IRefs.map((comment) => comment.id)
      const payload = {
        ids: ids
      }

      const likesMap = await axios.post("https://api.clinicalphenomics.org/raiting/CRI/reference/getByUser", payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })



      setLikedMap(new Map(Object.entries(likesMap.data)))
      

    }


    if(document.cookie != ""){
    getLikes()
    }
  
  }, [IRefs, liked ])



  React.useEffect(() =>{
    const fetchEntity = async () => {
      const newEntity = await axios.get(`https://api.clinicalphenomics.org/reference/incidence/${entity.id}`)
      
      for (let i = 0; i < newEntity.data.length; i++) {
        if(newEntity.data[i].percentage != null) {
        newEntity.data[i].percentage =  parseInt(newEntity.data[i].percentage * 1000);
        }
        if(selected.length != 0 && selected.id == newEntity.data[i].id) {
          const newArr = {...selected}
          newArr.row = newEntity.data[i]
          setSelected(newArr)
        }
      
      }
    setIRefs(newEntity.data);

    }

    if(!editMode) {
    fetchEntity()
    }

  }, [editMode, liked])

  const handleUpdate = async () => {
    const updatedOrNew = IRefs.filter((IRef) => !origCopy.includes(IRef))
    var updated = updatedOrNew.filter((IRef) => !IRef.newValue)
    var newRef = updatedOrNew.filter((IRef) => IRef.newValue)
    var deletedOrUpdated = origCopy.filter((ref) => (!IRefs.includes(ref)))
    var updatedIds = updated.map(IRef => IRef.id);
    var deleted = deletedOrUpdated.filter((ref) => !updatedIds.includes(ref.id))
  

    for(const ref of deleted) {
      await axios.delete(`https://api.clinicalphenomics.org/reference/${ref.id}`, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }

    for(const ref of updated) {
      if(!handleValidation(ref)) {
        return
      }
      
      let payload = {...ref}
      delete payload["id"]
      delete payload["newValue"]
      payload["percentage"] = ref.percentage != null ? parseFloat(ref.percentage)/1000.0: null


      await axios.put(`https://api.clinicalphenomics.org/reference/${ref.id}`, payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }

    for(const ref of newRef) {
      if(!handleValidation(ref)) {
        return
      }

      let payload = {...ref}
      delete payload["id"]
      delete payload["newValue"]
      payload["type"] = "Disease Incidence"
      payload["type_id"] = entity.id
      payload["percentage"] = parseFloat(ref.percentage)/1000.0
      payload["num_of_likes"] = 0


      await axios.post(`https://api.clinicalphenomics.org/reference/`, payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }

    setUpdatedNew(!updatedNew)
    setSelected([])
    setEditMode(false)

  }


  const handleAddRow = () => {
    let newRows = [...IRefs]; 
    let newRow = {id: maxId, newValue: true};
    let newId = maxId + 1
    setMaxId(newId); 
    newRows.push(newRow); 
    setIRefs(newRows); 
  };
  const handleValidation = (ref) => {
    ref.pmid = ref.pmid || 0
    ref.num_patients = ref.num_patients || 0
    ref.percentage = ref.percentage === undefined ? "": ref.percentage


    if(isNaN(ref.pmid) || parseInt(ref.pmid) < 0) {
      alert("You must enter a number greater than 0 for PMID")
      return false
    }

    if(isNaN(ref.num_patients) || parseInt(ref.num_patients) < 0) {
      alert("You must enter a number greater than 0 for number of patients")
      return false
    }

    if(isNaN(ref.percentage) || parseInt(ref.percentage) < 0 || parseInt(ref.percentage) >= 100000) {
      alert("You must enter a number between 0 and 100000 for #/100000")
      return false
    }

    return true
  }
  const handleDelete =   () => {
    const filtered = IRefs.filter((obj) => obj.id != selected.id)
    setIRefs(filtered)
    setSelected([])
  }


  const handleEditMode = async () => {
    if(document.cookie == "") {
      alert("You must be logged in to post a reference")
      return;
    }


    const newEntity = await axios.get(`https://api.clinicalphenomics.org/reference/user/incidence/${entity.id}`,             
    {
      headers: {
        Authorization: `Bearer ${document.cookie}`,
      },
    })

    let maxNum = 0;


    for (let i = 0; i < newEntity.data.length; i++) {
      if(newEntity.data[i].percentage != null) {
      newEntity.data[i].percentage =  parseInt(newEntity.data[i].percentage * 1000);
      }
      if(newEntity.data[i].id > maxNum) {
        maxNum = newEntity.data[i].id
      }

      newEntity.data[i].newValue = false
    }

    maxNum++;


    setMaxId(maxNum)
    setIRefs(newEntity.data)

    const copy = [...newEntity.data]
    setOrigCopy(copy)


    setEditMode(true)
  }


  const handleProcessRowUpdateError = (error) => {
    console.error('Error updating row:', error);
    };

  const handleCellEditCommit =  (params) => {
    const rowIndex = IRefs.findIndex(row => row.id == params.id);
    if (rowIndex !== -1) {
    const updatedRows = [...IRefs];
    updatedRows[rowIndex] = params
    setIRefs(updatedRows)
    return updatedRows[rowIndex]
  }
  }

  const handleRowClick = (rowData, event) => {
    setSelected(rowData);
  }


    
  const columns = [


    {
      field: 'pmid',
      headerName: 'PMID',
      width: 120,
      editable: editMode,
      valueGetter: (params) => {return params.value || ""}

    },

    {field: 'reference',
     headerName: 'Reference Link',
     width: 350,
     editable: editMode,

  },

  {
    field: "percentage",
    headerName: '#/100000',
    width: 150,
    editable: editMode,
    valueGetter: (params) => {return params.value != null ? params.value : ""}


  },

  {field: "num_patients",
   headerName: "Number of Patients",
   width: 200, 
   editable: editMode,
   valueGetter: (params) => {return params.value || ""}
  }
  ]



  
  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>



    <Box sx={{ height: 400, width: '100%' }}>
    <h2>
  Add Incidence References
  <ModeEditIcon color="primary" style={{marginLeft: "10px"}} onClick={handleEditMode} />
</h2>
<div style={{ position: 'relative', top: '0px', left: '0px' }}>

    <DataGrid
      rows={IRefs}
      columns={columns}
      onRowClick={handleRowClick}
      processRowUpdate={handleCellEditCommit}
      onProcessRowUpdateError={(error, updatedRows, params) => handleProcessRowUpdateError(error, updatedRows, params)}  
      slots={{

        toolbar: () => (
          <GridToolbarContainer>
            {editMode && <IconButton color="primary" aria-label="add" onClick={handleAddRow}> + </IconButton>}

            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />

          </GridToolbarContainer>
        )
        
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 3,
          },
        },
      }}
      pageSizeOptions={[5]}
    />

    
          </div>


{(selected.length != 0 && !editMode) && 
<>
  <div style={{ display: 'flex', justifyContent: 'center' }}> 
  
  <CommentModal type="reference" id={selected.id}  commentStyle={{ height: '30px', marginRight: '20px', marginTop: '6px' }} />
  <ThumbUp onClick={() => handleLike(selected.id)} color={likedMap.has(String(selected.id)) ? "primary" : "default"} style={{ height: '30px', margin: '10px 10px 10px 30px' }} />
  <span style={{marginTop: '15px', marginRight: '15px'}}>  {selected.row.num_of_likes}</span>
  <AddFlagModal imageType="plus" imageStyle={{ height: '30px', marginLeft: '20px', marginTop: '10px' }} type="Reference" entity={selected} />
   <Tooltip title="Question">
    <Flag  onClick={() => {setSeeFlagOpen("Question")}} style={{ color: 'red',  marginTop: '10px'}}  />
    </Tooltip>
    {seeFlagOpen =="Question" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Question" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}}>  {selected.row.numQFlags}</span>
    <Tooltip title="Comment">
    <Flag onClick={() => {setSeeFlagOpen("Comment")}} style={{ color: 'orange', marginTop: '10px'}}  />
    </Tooltip> 
    {seeFlagOpen =="Comment" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Comment" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}}> {selected.row.numCFlags} </span>
    <Tooltip title="Verify">
    <Flag onClick={() => {setSeeFlagOpen("Verify")}} style={{ color: 'green', marginTop: '10px'}}   />
    </Tooltip>
    {seeFlagOpen =="Verify" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Verify" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}} > {selected.row.numVFlags} </span>
    <Tooltip title="Review">
    <Flag onClick={() => {setSeeFlagOpen("Review")}} style={{ color: 'blue', marginTop: '10px'}}   />
    </Tooltip>
    {seeFlagOpen =="Review" && <SeeFlagModal type = "Reference" id={selected.id} flag_type="Review" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
    <span style={{marginTop: '12px'}}> {selected.row.numRFlags} </span>

  </div>
  {(!editMode && selected.row && selected.row.User) && 

<div>
    
  <TableContainer   component={Paper}>
  <Table  sx={{ minWidth: 650 }} aria-label="simple table">
   <TableBody >

   <TableRow 
        key={selected.row.User.first_name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
         Created By
        </TableCell>
        {selected.row.User != null && <TableCell align="right"> {selected.row.User.first_name} {selected.row.User.last_name} </TableCell>}

      </TableRow>

   </TableBody>

  </Table>
  </TableContainer>
  <div style={{ height: '20px' }}></div>

  </div>
  }

  </>
 }

          
{editMode && <Button variant="contained" onClick={handleUpdate} style={{marginTop: "10px"}}  color="primary" > Save </Button>}
{(editMode && IRefs.length > 0) && <Button variant="contained" onClick={handleDelete} style={{marginTop: "10px", marginLeft: "10px"}}  color="error" > Delete Selected </Button>}


  </Box>
  
  </div>

    )}
  
  

    export default IncidenceEditTab;