import React, {useContext, useEffect} from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid, Box, Icon} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Popover from '@mui/material/Popover';
import OptButton from "../OptButton/OptButton"
import axios from 'axios';
import {FilterContext} from "../../../../contexts/FilterContext"

function ExpirienceModal({selected, setFilterSelect}) {
    const [showPopup, setShowPopup] = React.useState(null);
    const [showApplyButton, setShowApplyButton] = React.useState(false)
    const {fetchFilterIds, knowledgeFilter} = useContext(FilterContext);
    const [filterSelected, setFilterSelected] = React.useState(knowledgeFilter);

    useEffect(() => {
      setFilterSelected(knowledgeFilter);
   }, [knowledgeFilter]); 



     const handleMouseEnter = (event) => {
      setShowPopup(event.currentTarget);
     };

    
     const handleMouseLeave = () => {
       setShowPopup(null);
     };

     const selectFilter = (value) => {
      setShowApplyButton(true);
      setFilterSelected(value);

     }
    
     const popupOpen = Boolean(showPopup)
      
      const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: "24vh",
        pt: "2vh",
        px: "4vh",
        pb: "3vh",
        maxHeight: '90vh',
        overflowY: 'auto'
      };
    
      const [open, setOpen] = React.useState(false);
    
      const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      const handleFiltration = async () => {
        await fetchFilterIds(filterSelected)
        handleClose();
     }
      
    
      return (
        <div>
        <Button 
        onClick = {handleOpen}  variant="contained"> Filter Experience </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, overflowY: 'auto'}}>
            <div style={{ position: 'absolute', top: '1vh', left: '0.25vw' }}>
    
              <CloseIcon onClick={handleClose} />
    
            </div>
    
            <div style={{ position: 'absolute', top: '1vh', right: '0.25vw' }}>
    
            <HelpOutlineIcon onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
    
            <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={popupOpen}
            anchorEl={showPopup}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleMouseLeave}
            disableRestoreFocus
          >
          <div style={{ padding: '8px' }}>
    
            <h2>  Experience Levels</h2>
            <p> 1 - Undergrad Student</p>
            <p> 2 </p>
            <p> 3 </p>
            <p> 4 </p>
            <p> 5 </p>
            <p> 6 </p>
            <p> 7 </p>
            <p> 8 </p>
            <p> 9 - Expert </p>
    
          </div>
    
          </Popover>
    
    
            </div>
    
    
    
              <h2> Filter Experience Level </h2>
    
    
    
    
    
              
              <div style={{display: 'flex' }}>
                <OptButton label= '1' onClick={() => selectFilter(1)} disabled={filterSelected == 1}/>
                <OptButton label= '2' onClick={() => selectFilter(2)} disabled={filterSelected == 2} leftmarg="5px" />
                <OptButton label= '3' onClick={() => selectFilter(3)} disabled={filterSelected == 3} leftmarg="5px" />
                <OptButton label= '4' onClick={() => selectFilter(4)} disabled={filterSelected == 4} leftmarg="5px" />
                <OptButton label= '5' onClick={() => selectFilter(5)} disabled={filterSelected == 5} leftmarg="5px" />
                <OptButton label= '6' onClick={() => selectFilter(6)} disabled={filterSelected == 6} leftmarg="5px" />
                <OptButton label= '7' onClick={() => selectFilter(7)} disabled={filterSelected == 7} leftmarg="5px" />
                <OptButton label= '8' onClick={() => selectFilter(8)} disabled={filterSelected == 8} leftmarg="5px" />
                <OptButton label= '9' onClick={() => selectFilter(9)} disabled={filterSelected == 9} leftmarg="5px" />
    
    
              </div>

              <Box display="flex" marginTop= "2vh" justifyContent="center" alignItems="center">

              {showApplyButton && <Button variant="contained" onClick={handleFiltration}> Apply </Button> }

              </Box>
    
    
            </Box>
          </Modal>
        </div>
      );
      
      
    
    
    
    }

    export default ExpirienceModal;
