import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import {GridToolbarColumnsButton,  GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Container, TextField } from "@mui/material";
import { Grid, Box, Icon} from '@mui/material';
import BasicAccordion  from '../BasicAccordion/BasicAccordion';
import ChipModal from '../ChipModal/ChipModal';
import OptButton from '../OptButton/OptButton';
import ColorTabs from './ColorTabs/ColorTabs'
import TextBox from '../TextBox/TextBox'
import Chip from '@mui/material/Chip';
import { v4 as uuidv4 } from 'uuid';
import CancelIcon from '@mui/icons-material/Cancel';
import {useNavigate } from 'react-router-dom';



function NestedModal({type, onUpdate, setOnUpdate}) {

    const navigate = useNavigate();  
    const style = {
      position: 'absolute',
      top: '50vh',
      left: '50vw',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: "24vh",
      pt: "2vh",
      px: "4vh",
      pb: "3vh",
      maxHeight: '90vh',
      overflowY: 'auto'
    };
  
  
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = React.useState("tab1");

    






    const validValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

  
  
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    
    const handleActive = (tab) => {
      setActive(tab);
    }
  
    const handleCreateDisease = async (event) => {

      if(document.cookie == "") {
        alert("You need to be logged in or registered to create a disease or finding")
        return
      }

      if(nameDisease == "") {
        alert("Disease name cannot be null")
        return
      }

      if(descriptionDisease == "") {
        alert("Disease description cannot be null")
        return
      }


      
      //Add validation checking'
  
      const parentIds = selectedEntity.filter(obj => obj.type.split(" ")[1]=== "parents").map(obj => obj.id)
      const childrenIds = selectedEntity.filter(obj => obj.type.split(" ")[1] === "children").map(obj => obj.id)



      try {
      const newDisease = await axios.post(
        `https://api.clinicalphenomics.org/${type.split(" ")[0].toLowerCase()}`,
        {name: nameDisease, description: descriptionDisease, parents: parentIds, 
          children: childrenIds},
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          },
        }
      )



      
        

     const upperType = type.split(" ")[0].charAt(0).toUpperCase() + type.split(" ")[0].slice(1)
     
     if(commentDisease) {


      await axios.post(
        `https://api.clinicalphenomics.org/comment`,
        {body: commentDisease, type: upperType, type_id: newDisease.data.id},
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`
          }})
        
      }

      setCommentDisease("")

      if(knowledgeDisease) {

        if(!validValues.includes(knowledgeDisease)) {
          alert("Knowledge must be an integer from 1 to 9")
          return
        }
        await axios.post('https://api.clinicalphenomics.org/raiting/DorF', 
        {type: upperType, knowledge: parseFloat(knowledgeDisease), selected: false, type_id: newDisease.data.id},
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          }})  
      }

      setKnowledgeDisease("")
      setNameDisease("")
      setDescriptionDisease("")
 

    for(const image of imagesToAdd) {


      const [fileNameWithoutExtension, fileExtension] = image.image.name.split('.');


      const newImage = await axios.post(
        `https://api.clinicalphenomics.org/image`,
        {
          title: image.title,
          description: image.description,
          type: upperType,
          body_site: image.bodySite,
          id: newDisease.data.id,
          extension: fileExtension
        },
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          },
        }
      )

      const formData = new FormData();



      const modifiedFileName = `${newImage.data.id}.${fileExtension}`;

      formData.append('file', image.image); 
      formData.append('filename', modifiedFileName ); 


      await axios.post("https://api.clinicalphenomics.org/image/upload", 

      formData, {
        headers: {
          Authorization: `Bearer ${document.cookie}`,
                          'Content-Type': 'multipart/form-data'
        }
      })
      
      if(image.comment) {

        await axios.post(
          `https://api.clinicalphenomics.org/comment`,
          {body: image.comment, type: "Image", type_id: newImage.data.id},
          {
            headers: {
              Authorization: `Bearer ${document.cookie} `,
            }})
          
        }}


    setImagesToAdd([])

    

    if(type.split(" ")[0] == "disease") {



    for(const prevalence of PRefs) {
     if(prevalence.link == "" || !Boolean(prevalence.percent) || prevalence.pmid == "" || prevalence.number == "") {
      alert("Prevalence reference link, percentage, pmid, and number of patients cannot be null ")
      return;
     }

      await axios.post(
        `https://api.clinicalphenomics.org/reference`,
        {
          type: "Disease Prevalence",
          reference: prevalence.link,
          percentage: parseFloat(prevalence.percent)/1000.0,
          num_of_likes: 0,
          pmid: prevalence.pmid,
          num_patients: prevalence.number,
          type_id: newDisease.data.id
        },
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          },
        })}

      setPRefs([])

      for(const incidence of IRefs) {
        if(incidence.link == "" || !Boolean(incidence.percent) || incidence.pmid == "" || incidence.number == "") {
          alert("Incidence reference link, percentage, pmid, and number of patients cannot be null ")
          return;
         }

      await axios.post(
        `https://api.clinicalphenomics.org/reference`,
        {
          type: "Disease Incidence",
          reference: incidence.link,
          percentage: parseFloat(incidence.percent)/1000.0,
          num_of_likes: 0,
          pmid: incidence.pmid,
          num_patients: incidence.number,
          type_id: newDisease.data.id
        },
        {
          headers: {
            Authorization: `Bearer ${document.cookie}`,
          },
        })}

        setIRefs([])




    
  
  
  }


  setOnUpdate(!onUpdate)
  setOpen(false)
} catch(error) {
  console.log(error + " error")
}




}
  
  
    
    const commonStyles = {
      bgcolor: 'background.paper',
      m: '6px',
      borderColor: 'text.primary',
      width: '250px',
      position: 'relative',
      height: '375px',
    };
  
    const commonStylesTwo = {
      bgcolor: 'background.paper',
      m: '6px',
      borderColor: 'text.primary',
      width: '250px',
      position: 'relative',
      height: '350px',
    };
  
    const [PRefs, setPRefs] = React.useState( [
    ]);

    const [IRefs, setIRefs] = React.useState( [
    ])
  
    const [nameDisease, setNameDisease] = React.useState('');
    const handleNameDisease = (event) => {
      setNameDisease(event.target.value)
    }
  
    const [descriptionDisease, setDescriptionDisease] = React.useState('');
    const handleDescriptionDisease = (event) => {
      setDescriptionDisease(event.target.value)
    }
  
    const [commentDisease, setCommentDisease] = React.useState('');
    const handleCommentDisease = (event) => {
      setCommentDisease(event.target.value)
    }
  
    const [knowledgeDisease, setKnowledgeDisease] = React.useState('');
    const handleKnowledgeDisease = (event) => {
      setKnowledgeDisease(event.target.value)
    }

    const [title, setTitle] = React.useState('');
    const handleTitle = (event) => {
      setTitle(event.target.value);
    }

    const [description, setDescription] = React.useState('');
    const handleDescription = (event) => {
      setDescription(event.target.value);
    }

    const [bodySite, setBodySite] = React.useState('');
    const handleBodySite = (event) => {
      setBodySite(event.target.value);
    }

    const [comment, setComment] = React.useState('');
    const handleComment = (event) => {
      setComment(event.target.value);
    }

    const [newFileImage, setNewFileImage] = React.useState(null)
    const handleImageClick = () => {
      if(newFileImage) {
        setNewFileImage(null)
      }

    }

    const handleFileSelect = (event) => {
      const file = event.target.files[0]
      if(file) {
        setNewFileImage(file)
      }
    }



    const [imagesToAdd, setImagesToAdd] = React.useState([])
    


    const handleAddImage = (event) => {
      if(title == "" || description == "" || bodySite == "") {
        alert("Title, description, and body site cannot be null")
        return;
      }

      if(newFileImage == null) {
        alert("You must add an image")
        return;
      }

      setImagesToAdd([...imagesToAdd, {title, description, bodySite, comment, image: newFileImage} ])
      setTitle("");
      setDescription("");
      setBodySite("")
      setComment("");
      setNewFileImage(null)
    }

    const handleImageDelete = (event, title, description, bodySite, comment) => {
      setImagesToAdd(imagesToAdd.filter(item => 
        item.title != title || 
        item.description != description || 
        item.bodySite != bodySite || 
        item.comment != comment))
    }

    const handleImageSelect = (event, title, description, bodySite, comment, image) => {
      setTitle(title)
      setDescription(description)
      setBodySite(bodySite)
      setComment(comment)
      setNewFileImage(image)
    }

  
    const [selectedEntity, setSelectedEntity] = React.useState([])

    const uploadImages = async() => {

    }
  
  
  
  
  
  
    const renderContent = () => {
      if(active == "tab1") {
        return (
          <div>
          <h2 id="parent-modal-title">Add {type} Info</h2>
  
  
          <div   style={{display: 'flex', alignItems: 'center'}}>
  
            
            <typography> Name: </typography>
          </div>
  
          <div style={{marginBottom: '3%'}}>
         <TextField   onChange={handleNameDisease} id="outlined-basic" value={nameDisease} multiline inputProps={{paddingTop: 0, style: {minHeight: `18px`, maxHeight: `18px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" />  
         </div> 
  
          <div >
            <typography> Description: </typography>
          </div>
          <div style={{marginBottom: '3%'}}>
          <TextField   onChange={handleDescriptionDisease} id="outlined-basic" value={descriptionDisease} multiline inputProps={{paddingTop: 0, style: {minHeight: `100px`, maxHeight: `100px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" />  
  
          </div>
  
          <div >
            <typography> Comment: </typography>
          </div>
          <div style={{marginBottom: '3%'}}>
          <TextField   onChange={handleCommentDisease} id="outlined-basic" value={commentDisease} multiline inputProps={{paddingTop: 0, style: {minHeight: `72px`, maxHeight: `72px`, minWidth: `500px`, maxWidth: `500px`, textAlign: 'left'} }} variant="outlined" />  
          </div>


  
          <div style={{marginBottom: '3%', display: 'flex', alignItems: 'center'}}>
            <typography style={{textAlign: 'center', marginRight: '10px '}}> Knowledge: </typography>
            <TextField   onChange={handleKnowledgeDisease} id="outlined-basic" value={knowledgeDisease} multiline inputProps={{paddingTop: 0, style: {minHeight: `20px`, maxHeight: `20px`, minWidth: `50px`, maxWidth: `50px`, textAlign: 'left'} }} variant="outlined" />  
  
          </div>
  
          </div>
          )
      }
  
      else if(active == "tab2") {
        return (
          <div>
  <h2 id="parent-modal-title">Add Parents and Children</h2>
    <div style={{ display: 'flex',  }}>
      <div style={{ marginRight: '50px' }}>
        <Typography style={{ marginBottom: '10px' }}>Select Parents:</Typography>
      </div>
      <div style={{ marginLeft: '490px' }}>
        <Typography variant="body1" style={{ marginBottom: "10px" }}>Select Children:</Typography>
      </div>
    </div>
  
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ position: 'relative', top: '0px', left: '0px' }}>
            <BasicAccordion width="300px" widthBox="350px" type={`${type} parents`} selected={selectedEntity} setSelected={setSelectedEntity}/>
            </div>
  
          <div style={{ width: '300px' }}>
  
          <Box sx={{ display: 'flex', justifyContent: 'center', top: '0px', left: '0px' }}>
          <Box sx={{ ...commonStyles, border: 1, marginBottom: '30px' }}>
          <ChipModal selected={selectedEntity} setSelected={setSelectedEntity}/>
          </Box> 
          </Box>
          </div>
          <div style={{ position: 'relative', marginLeft: 'auto', top: '0px', right: '0px' }}>
            <BasicAccordion width="300px" widthBox="350px" type={`${type} children`} selected={selectedEntity} setSelected={setSelectedEntity}/>
          </div>
  
         </div>
         </div>
        )
      }
  
      else if(active == "tab3") {
        return (
        <div>
  
        <h2 id="parent-modal-title">Add Images</h2>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
  
        <div style={{ position: 'relative', top: '0px', left: '0px' }}>
            <Typography style={{ marginBottom: '5px' }}> Title: </Typography>
            <TextBox height="18" width="300" value={title} handleValueChange={handleTitle} />
  
            <Typography style={{ marginTop: '5px' }}> Description: </Typography>
            <TextBox height="72" width="300" value={description} handleValueChange={handleDescription}/>
  
            <Typography style={{ marginTop: '5px' }}> Body Site: </Typography>
            <TextBox height="18" width="300" value={bodySite} handleValueChange={handleBodySite}/>
  
            <Typography style={{ marginTop: '5px' }}> Comment: </Typography>
            <TextBox height="18" width="300" value={comment} handleValueChange={handleComment}/>
            
            {newFileImage != null && <Typography style={{ marginTop: '5px' }}> File Uploaded: {newFileImage.name} </Typography>}

            
            <div>
 
            <Button component="label" onClick={handleImageClick} style={{marginTop: 10}} variant="contained" > Browse Image 
            <input
            type="file"
            ref={newFileImage}
            style={{ display: 'none' }}
            accept="image/*"
            hidden
            onChange={handleFileSelect}
          />
          </Button>
            <OptButton  label="Add Image" leftmarg={65} marg={10} onClick={handleAddImage}/>
  
            </div>
        </div>
        <div style={{ width: '15px' }}>
          </div>
  
  
        <div> 
  
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ ...commonStylesTwo, border: 1 }}>

          {imagesToAdd.map((image) => (
          <Chip sx={{marginTop: '10px', width: '100%' , justifyContent: 'space-between'}} color="default" onDelete={(event) => handleImageDelete(event, image.title, image.description, image.bodySite, image.comment)}  deleteIcon={<CancelIcon />} key={uuidv4()} label={image.title} onClick={(event) => handleImageSelect(event, image.title, image.description, image.bodySite, image.comment, image.image)} />
          ))}
          </Box>
             </Box>
  
  
        </div>
        </div>
        </div>)
      }
  
      else if(active == "tab4") {
        const handleAddRow = () => {
          const newRows = [...PRefs]; 
          const newRow = {id: PRefs.length + 1}; 
          newRows.push(newRow); 
          setPRefs(newRows); 
        };

        
        const handleProcessRowUpdateError = (error) => {
          console.error('Error updating row:', error);
          };

        const handleCellEditCommit = async (params) => {
          const rowIndex = PRefs.findIndex(row => row.id == params.id);
          if (rowIndex !== -1) {
          const updatedRows = [...PRefs];
          const updatedParams = {...params}
          updatedRows[rowIndex] = updatedParams

          setPRefs(updatedRows)


          return updatedRows[rowIndex]
        } 
      }
        
          
        const columns = [
          {
            field: 'pmid',
            headerName: 'PMID',
            width: 120,
            editable: true
          },
  
          {field: 'link',
           headerName: 'Reference Link',
           width: 350,
           editable: true
        },
  
        {
          field: "percent",
          headerName: '#/100000',
          width: 150,
          editable: true
        },
  
        {field: "number",
         headerName: "Number of Patients",
         width: 200, 
         editable: true}
        ]
  
  
  
        
        return (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
  
  
  
          <Box sx={{ height: 400, width: '100%' }}>
          <h2>
        Add Prevalence References
      </h2>
      <div style={{ position: 'relative', top: '0px', left: '0px' }}>
  
          <DataGrid
            rows={PRefs}
            columns={columns}
            processRowUpdate={handleCellEditCommit}
            onProcessRowUpdateError={(error, updatedRows, params) => handleProcessRowUpdateError(error, updatedRows, params)}  
            slots={{
  
              toolbar: () => (
                <GridToolbarContainer>
                  <IconButton color="primary" aria-label="add" onClick={handleAddRow}> + </IconButton>
  
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />
  
                </GridToolbarContainer>
              )
              
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 3,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
  
          
                </div>
  
        </Box>
        
        </div>
  
        )
  
      
  
  
  
        
      }
  
      else if(active == "tab5") {
        
        const handleAddRow = () => {
          const newRows = [...IRefs]; 
          const newRow = {id: IRefs.length + 1}; 
          newRows.push(newRow); 
          setIRefs(newRows); 
        };

        const handleProcessRowUpdateError = (error) => {
          console.error('Error updating row:', error);
          };

        const handleCellEditCommit =  (params) => {
          const rowIndex = IRefs.findIndex(row => row.id == params.id);
          if (rowIndex !== -1) {
          const updatedRows = [...IRefs];
          updatedRows[rowIndex] = params
          setIRefs(updatedRows)
          return updatedRows[rowIndex]
        }




   


        }
          
        const columns = [


          {
            field: 'pmid',
            headerName: 'PMID',
            width: 120,
            editable: true
          },
  
          {field: 'link',
           headerName: 'Reference Link',
           width: 350,
           editable: true
        },
  
        {
          field: "percent",
          headerName: '#/100000',
          width: 150,
          editable: true
        },
  
        {field: "number",
         headerName: "Number of Patients",
         width: 200, 
         editable: true}
        ]
  
  
  
        
        return (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
  
  
  
          <Box sx={{ height: 400, width: '100%' }}>
          <h2>
        Add Incidence References
      </h2>
      <div style={{ position: 'relative', top: '0px', left: '0px' }}>
  
          <DataGrid
            rows={IRefs}
            columns={columns}
            processRowUpdate={handleCellEditCommit}
            onProcessRowUpdateError={(error, updatedRows, params) => handleProcessRowUpdateError(error, updatedRows, params)}  
            slots={{
  
              toolbar: () => (
                <GridToolbarContainer>
                  <IconButton color="primary" aria-label="add" onClick={handleAddRow}> + </IconButton>
  
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                  <GridToolbarDensitySelector />
                  <GridToolbarExport />
  
                </GridToolbarContainer>
              )
              
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 3,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
  
          
                </div>
  
        </Box>
        
        </div>
  
          )}
  
    }
  
  
    return (
      <div>
      <Button   style={{maxWidth: '3vw', maxHeight: '3vw', minWidth: '3vw', minHeight: '3vw'}}
      onClick = {handleOpen}  variant="contained"> + </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, overflowY: 'auto'}}>
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
  
            <CloseIcon onClick={handleClose} />
  
            </div>
  
            <ColorTabs activeTab={active} onTabChange={handleActive} type={type}/>
  
  
  
  
            {renderContent()}
            
            <div style={{display: 'flex', justifyContent: 'flex-end' }}>
              <OptButton onClick={handleCreateDisease} label= {'Create ' + type} />
            </div>
  
  
          </Box>
        </Modal>
      </div>
    );
  }
  
export default NestedModal;  