import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { Grid, Box, Icon} from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import axios from "axios";
import React, {useContext} from 'react';
import { FilterContext } from '../../../../contexts/FilterContext';


function BasicAccordion({widthBox, width, selected, setSelected, filSelected, setFilSelect, type, onUpdate}) {

    const [listOfDiseases, setListOfDiseases] = React.useState([])
    const [diseasesSelected, setDiseasesSelected] = React.useState({})
    const {diseaseIds, findingIds, knowledgeFilter} = useContext(FilterContext);

  
  
    React.useEffect(() => {

      const getAllNames = async () =>  {
        const filterIds = type.split(" ")[0] === "disease" ? diseaseIds : findingIds;
        const diseaseNames = await axios.post(`https://api.clinicalphenomics.org/${type.split(" ")[0]}/names/0` , {filterIds})
        
      if(diseaseNames != "No disease exists") {
      setListOfDiseases(diseaseNames.data);
      }
      }
      getAllNames()
    }, [type, onUpdate, diseaseIds, findingIds])


 
  
    const commonStyles = {
      bgcolor: 'background.paper',
      borderColor: 'text.primary',
      width: `${widthBox}`,
      height: '40vh',
      overflowY: 'auto'
    };
  
    const handleAccordionClick = async (event, id, name, knowledge, iconClicked, index, unselect ) => {
     
     
      // Prevent the default behavior of the Accordion from expanding/collapsing
      // when clicked. Instead, toggle the "expanded" state manually.
      
      if(iconClicked) {

  
        if(unselect) {

          const updatedDiseases = { ...diseasesSelected};
          delete updatedDiseases[index]
          setDiseasesSelected(updatedDiseases);
        }
  
        if(!unselect) {
        const filterIds = type.split(" ")[0] === "disease" ? diseaseIds : findingIds;
        const children = await axios.post(`https://api.clinicalphenomics.org/${type.split(" ")[0]}/names/${id}`, {filterIds})

  
      setDiseasesSelected({
        ...diseasesSelected,
        [index]: children.data
      })

      }
    }
  
      if(!iconClicked) {



  

        const isIdPresent = selected.find((disease) => disease.id === id && disease.type === type);
        if (!isIdPresent) {
          setSelected([...selected, {type:type, id: id, name: name, knowledge: knowledge}]);
          }
  
       
       if(filSelected) {
        const filIdPresent = filSelected.find((disease) => disease.id === id && disease.type === type);
        if (!filIdPresent) {
        setFilSelect([...filSelected, {type:type, id: id, name: name, knowledge: knowledge}]);
        }
      }


      }
    };
    const theme = useTheme();
  
    const accordionStyle = (key) => ({
      width: {width},
      backgroundColor: diseasesSelected.hasOwnProperty(key) ? theme.palette.grey[200] : 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    });
  
    const iconSelection = (disease, index)=> {
      if(disease.hasChildren == 0) {
        return;
      }
  
      else if(diseasesSelected.hasOwnProperty(index)) {
        return (<ExpandLessIcon onClick={(event) => {event.stopPropagation(); handleAccordionClick(event, disease.id, disease.name, disease.knowledge, true, index, true)}} 
        />); 
      }
  
      else {
        return ( <ExpandMoreIcon onClick={(event) => {event.stopPropagation(); handleAccordionClick(event, disease.id, disease.name, disease.knowledge, true, index, false)}} 
        />) 
      }
    }
  
    const renderAccordion = (diseases, parentIndex = null) => {
  
  
      return diseases.map((disease, index) => {
  
        const currentIndex = parentIndex !== null ? `${parentIndex}-${index}` : `${index}`;
       return (
        <div>
      <Accordion 
      style={accordionStyle(currentIndex)} 
      expanded={false}
      value={disease.name}
      onChange={(event) => handleAccordionClick(event, disease.id, disease.name, disease.knowledge, false)}
      > 
        <AccordionSummary
          expandIcon={iconSelection(disease, currentIndex)}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: '0 1 100%',
          }}
  
        >
          
  
  <div style={{ flex: 1, flexWrap: 'wrap' }}>
  
          <Typography style={{
            whiteSpace: 'normal',
            wordBreak: 'break-all'
          }}>{disease.name}</Typography>
          </div>
  
    </AccordionSummary>
  
    </Accordion>
    
    {diseasesSelected.hasOwnProperty(currentIndex) && renderAccordion(diseasesSelected[currentIndex], currentIndex)}
  
  </div>
  
  ) })}
  
    return (
      <div>
      <Box sx={{ ...commonStyles, border: 1 }} >
      
      {renderAccordion(listOfDiseases)}
  
  
  
      </Box>
    </div>
  
    );
  }

  export default BasicAccordion;