import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TablePagination from '@mui/material/TablePagination';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Grid, Box, Icon} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import axios from "axios";

import UpdateRelations from "../../UpdateModals/UpdateRelations/UpdateRelations"




function RelationsEditTab({user, type, entity}) {

     


  const [page, setPage] = React.useState(0);
  const [childPage, setChildPage] = React.useState(0);
  const [paths, setPaths] = React.useState([])
  const [parents, setParents] = React.useState([])
  const [children, setChildren] = React.useState([])
  const [editMode, setEditMode] = React.useState(false)

  const updatedPathRows = [];

    React.useEffect(() => {
      const getParents = async () => {

        const parent  = await axios.get(`https://api.clinicalphenomics.org/${type}/children/${entity.id}`)

          setParents(parent.data.map((disease) => disease.name))

    
      }

      const getChildren = async () => {
    
        const child = await axios.post(`https://api.clinicalphenomics.org/${type}/names/${entity.id}`, {filterIds: [-1]})

        const payload = child.data;

        if(child.data != "No disease exists") {
          setChildren(child.data.map((disease) => disease.name));
        }
      }

      getChildren()
      getParents()

      }, [editMode])


      const handleEditModeSwitch = () => {
        setEditMode(true)
      }
  
  




    paths.map((path, index) => {
    updatedPathRows[index] = <Box key={0} display="flex" alignItems="center"> 

    {path.map((disease, index) => (
      <React.Fragment key={index}>

      <Typography> {disease} </Typography>
      {index != path.length - 1  && <ArrowRightIcon sx={{ mx: 1 }} fontSize="small" /> }
      </React.Fragment>

    ))}

    </Box>

    })



    React.useEffect(() => {
      const fetchEntity = async () => {
        const newEntity = await axios.get(`https://api.clinicalphenomics.org/${type}/paths/${entity.id}`)
      setPaths(newEntity.data);

      }
      fetchEntity()
    }, [editMode])

    const renderContent = () => {
      if(editMode) {
        return <UpdateRelations type={type} entity={entity} setEditMode={setEditMode} />
      }
      else {
        return (
          <div style={{ alignItems: 'left' }}>
          <h2> Paths to Disease
          {Object.keys(user).length > 0 && user.id == entity.UserId && 
          <ModeEditIcon color="primary" onClick={handleEditModeSwitch} style={{marginLeft: "10px"}} />
           }
          </h2>
        
          <div style={{ display: 'flex', alignItems: 'center' , flexDirection: 'column'}}>
          <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
        
         <TableBody>
         {updatedPathRows.slice(page * 5, page * 5 + 5).map((row) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
            </TableRow>
          ))}
        
         </TableBody>
        
         </Table>
          </TableContainer>
        
        
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0px' }}>
          <TablePagination 
          rowsPerPageOptions={[5]}
          component="div"
          count={updatedPathRows.length}
          rowsPerPage={5}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
        
        />
        </div>
        
        <div>
        <h2> Parents
        </h2>
        
          <div style={{ display: 'flex', alignItems: 'center' , flexDirection: 'column'}}>
          <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
        
         <TableBody>
         {parents.slice(childPage * 5, childPage * 5 + 5).map((row) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
            </TableRow>
          ))}
        
         </TableBody>
        
         </Table>
          </TableContainer>
          </div>
          
        
          <div>
        
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0px' }}>
          <TablePagination 
          rowsPerPageOptions={[5]}
          component="div"
          count={parents.length}
          rowsPerPage={5}
          page={childPage}
          onPageChange={(event, newPage) => setChildPage(newPage)}
        />
        </div >
      
        
        
        
          </div>
        </div>
         <div> 
        <h2> Children
          </h2>
          
          <div style={{ display: 'flex', alignItems: 'center' , flexDirection: 'column'}}>
          <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
        
         <TableBody>
         {children.slice(childPage * 5, childPage * 5 + 5).map((row) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
            </TableRow>
          ))}
        
         </TableBody>
        
         </Table>
          </TableContainer>
          </div>
          
        
          <div>
        
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0px' }}>
          <TablePagination 
          rowsPerPageOptions={[5]}
          component="div"
          count={children.length}
          rowsPerPage={5}
          page={childPage}
          onPageChange={(event, newPage) => setChildPage(newPage)}
        />
        </div >
      
        
        
        
          </div>
        </div>
        </div>
          
        )
      }
    }



    return(
    <div> 
   {renderContent()}
   </div>
  )}

  export default RelationsEditTab;