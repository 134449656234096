import { Typography, Box } from "@mui/material";
import React from "react";
import axios from "axios";

import BasicAccordion from "../../BasicAccordion/BasicAccordion";
import ChipModal from "../../ChipModal/ChipModal";
import OptButton from "../../OptButton/OptButton";

function UpdateRelations ({type, entity, setEditMode})  {

    const [selectedEntity, setSelectedEntity] = React.useState([])
    const [copyOfRelations, setCopyOfRelations] = React.useState([])

    React.useEffect(() => {

        const setSelected = async () => {
            const children = await axios.post(`https://api.clinicalphenomics.org/${type}/names/${entity.id}`, {filterIds: [-1]})
            const parents = await axios.get(`https://api.clinicalphenomics.org/${type}/parents/${entity.id}`, {filterIds: [-1]})


            let allChildren = children.data == "No disease exists" ? [{}] : children.data
            let allParents = parents.data == "No disease exists" ? [{}] : parents.data

            allChildren.forEach((child) => {
                child.type = `${type} children`
            })

            allParents.forEach((parent) => {
                parent.type = `${type} parent`
            })

            const allRelations = [...allChildren, ...allParents]
            const copy = [...allRelations]


            setSelectedEntity(allRelations)
            setCopyOfRelations(copy)
            //{type:type, id: id, name: name, knowledge: knowledge}
        }

        setSelected()



    }, [])


    const commonStyles = {
        bgcolor: 'background.paper',
        m: '6px',
        borderColor: 'text.primary',
        width: '250px',
        position: 'relative',
        height: '375px',
      };

      const handleUpdateRelations = async () => {
        const deleted = copyOfRelations.filter(item => !selectedEntity.includes(item))
        const added = selectedEntity.filter((item) => !copyOfRelations.includes(item) )
        const addedParents = added.filter((item) => (item.type.split(" ")[1] === "parent") || item.type.split(" ")[1] === "parents").map((item) => item.id)
        const addedChildren = added.filter((item) => item.type.split(" ")[1] === "children").map((item) => item.id)
        const deletedParents = deleted.filter((item) => item.type.split(" ")[1] === "parent" || item.type.split(" ")[1] === "parents").map((item) => item.id)
        const deletedChildren = deleted.filter((item) => item.type.split(" ")[1] === "children").map((item) => item.id)



        const parentPayload = {
            idAdd: addedParents,
            idDelete: deletedParents
        }


        const childPayload = {
            idAdd: addedChildren, 
            idDelete: deletedChildren
        }


        await axios.put(`https://api.clinicalphenomics.org/${type.split(" ")[0].toLowerCase()}/changeParents/${entity.id}`,parentPayload, 
        {
            headers: {
              Authorization: `Bearer ${document.cookie}`,
            },
          })
        await axios.put(`https://api.clinicalphenomics.org/${type.split(" ")[0].toLowerCase()}/changeChildren/${entity.id}`, childPayload, 
        {
            headers: {
              Authorization: `Bearer ${document.cookie}`,
            },
          })

        setEditMode(false)      
    }

    return (
    <div>
    <h2 id="parent-modal-title">Add Parents and Children</h2>
      <div style={{ display: 'flex',  }}>
        <div style={{ marginRight: '50px' }}>
          <Typography style={{ marginBottom: '10px' }}>Select Parents:</Typography>
        </div>
        <div style={{ marginLeft: '490px' }}>
          <Typography variant="body1" style={{ marginBottom: "10px" }}>Select Children:</Typography>
        </div>
      </div>
    
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{ position: 'relative', top: '0px', left: '0px' }}>
              <BasicAccordion width="300px" widthBox="350px" type={`${type} parents`} selected={selectedEntity} setSelected={setSelectedEntity}/>
              </div>
    
            <div style={{ width: '300px' }}>
    
            <Box sx={{ display: 'flex', justifyContent: 'center', top: '0px', left: '0px' }}>
            <Box sx={{ ...commonStyles, border: 1, marginBottom: '30px' }}>
            <ChipModal selected={selectedEntity} setSelected={setSelectedEntity}/>
            </Box> 
            </Box>
            </div>
            <div style={{ position: 'relative', marginLeft: 'auto', top: '0px', right: '0px' }}>
              <BasicAccordion width="300px" widthBox="350px" type={`${type} children`} selected={selectedEntity} setSelected={setSelectedEntity}/>
            </div>


    
           </div>
           
           <div> 
            <OptButton onClick={handleUpdateRelations} label= {'Update ' + type} />

            </div>
           </div>
    )
}

export default UpdateRelations;