import NavBar from "../../components/NavBar/NavBar";
import React from 'react';
import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import axios from "axios";
import {useNavigate } from 'react-router-dom';

function LoginPage() {

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")

    const navigate = useNavigate();

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const loginUser = async () =>{

        const payload = {
            email: email,
            password: password
        }

        try {
            const response = await axios.post('https://api.clinicalphenomics.org/login', payload)
            document.cookie = response.data.token
            navigate("/")
        } catch(err) {


            if(err.response.status == 402 && err.response.data.message == "No user exists") {
                alert("No user exists. Please register instead")
                return;
            }

            else if(err.response.status == 401 && err.response.data.message == "Incorrect password") {
                alert("Username and Password combination is incorrect")
                return;
            }
            
        }
        

    }


    return (
        <div>
  <NavBar />

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  width: '100vw', height: '40vh', justifyContent: 'center'  }}>
  <h1 style={{ fontSize: '2rem', marginBottom: '20px' }}>Login</h1>

  
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="email" style={{ width: '70px', marginRight: '10px' }}> Email:</label>
    <TextField id="email" value={email} onChange={handleEmail}   />
  </div>

  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <label htmlFor="password" style={{ width: '70px', marginRight: '10px' }}> Password:</label>
    <TextField id="password" type="password" value={password} onChange={handlePassword} />
  </div>

  <Button variant="contained" onClick={loginUser} > Login </ Button> 


   </div>
   </div>
    )

}

export default LoginPage;