import { Grid, Box, Icon} from '@mui/material';
import { ThumbUp, Flag, Reply, ModeEdit, ArrowBackIosNew } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CommentIcon from '@mui/icons-material/Comment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';

import AddFlagModal from '../AddFlagModal/AddFlagModal'
import CommentModal from '../CommentModal/CommentModal';
import UpdateImage from '../UpdateModals/UpdateImage/UpdateImage'
import SeeFlagModal from '../SeeFlagModal/SeeFlagModal';

function ImageTab({type, entity, edit = true}) {
    
  
    function createData(name, statistic) {
      return { name, statistic};
    }
    

  const [allImages, setAllImages] = React.useState([])
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [editMode, setEditMode] = React.useState(false)
  const [likedMap, setLikedMap] = React.useState(new Map())
  const [onImageChange, setOnImageChange] = React.useState(false)
  const [seeFlagOpen, setSeeFlagOpen] = React.useState("")
  const [photo, setPhoto] = React.useState('')
  const [dim, setDim] = React.useState([0,0])



  React.useEffect(() => {
    const getLikes = async () => {
      if(allImages.length == 0) {
        return;
      }

      const ids = allImages.map((comment) => comment.id)
      const payload = {
        ids: ids
      }

      const likesMap = await axios.post("https://api.clinicalphenomics.org/raiting/CRI/image/getByUser", payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })



      setLikedMap(new Map(Object.entries(likesMap.data)))
    }


    if(document.cookie != ""){
    getLikes()
    }
  
  }, [allImages, onImageChange])



  const handleLike = async (id) => {
    if(likedMap.has(String(id))) {

      await axios.delete(`https://api.clinicalphenomics.org/raiting/CRI/${String(likedMap.get(String(id)))}`, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })
    }
    else {
      const payload = {
        type: "Image",
        liked: true,
        type_id: id
      }
      await axios.post(`https://api.clinicalphenomics.org/raiting/CRI`, payload, {
        headers: {
          Authorization: `Bearer ${document.cookie}`
        }
      })}


      setOnImageChange(!onImageChange)  
  }

  React.useEffect(() =>{
    const fetchEntity = async () => {
      const newEntity = await axios.get(`https://api.clinicalphenomics.org/image/all/${type}/id/${entity.id}`)
      setAllImages(newEntity.data);

      if(newEntity.data.length == 0) {
        return;
      }

      const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${newEntity.data[0].id}`)
      const img = new Image()
      img.src = url.data
      setDim([img.width, img.height])



      setPhoto(url.data)




    }
    fetchEntity()

  }, [editMode, onImageChange])

    const rows = (allImages.length > 0 && allImages[currentIndex].User)
    ? [
      createData('Created By', `${allImages[currentIndex].User.first_name} ${allImages[currentIndex].User.last_name}`),
      createData('Body Site', allImages[currentIndex].body_site),
    ]: []
  
  
    const handlePreviousImage = async () => {
      var index;


      if(allImages.length == 0) {
        index = 0;
     }
      else if(currentIndex == 0) {
        index = allImages.length - 1;
      }
      else {
        index = currentIndex - 1;        
      }

      setCurrentIndex(index)

      const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${allImages[index].id}`)
      const img = new Image();
      img.src = url.data
      setDim([img.width, img.height])
      setPhoto(url.data)
    };

    const handleEditMode = () => {
      setEditMode(true)
    }
  
    const handleNextImage = async () => {

      if(allImages.length == 0) {
        return;
      }

      let index = (currentIndex + 1) % allImages.length
      setCurrentIndex((currentIndex + 1) % allImages.length)
      const url = await axios.get(`https://api.clinicalphenomics.org/image/getImage/${allImages[index].id}`)
      const img = new Image();
      img.src = url.data
      setDim([img.width, img.height])



      setPhoto(url.data)


    };

    if(editMode) {

      if(document.cookie == "") {
        alert("You must be logged in to add images")
      }
      else {
      return <UpdateImage type={type} entity={entity} setEditMode={setEditMode} setIndex={setCurrentIndex}/>
      }
    }
  
    return (
      <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      {allImages.length > 0 && <h1> { allImages[currentIndex].title} </h1>}
        {allImages.length == 0 && 
        <h1> Add a new Image </h1>}

       
        {edit && <AddIcon onClick={handleEditMode} style={{marginLeft: '10px', color: 'gray'}} />}

        </div>

        {allImages.length > 0 &&

        <>
  
      <Box sx={{width: '400px', marginTop: "10px", marginBottom: "-20px", display: 'flex', flexDirection: 'row',  alignItems: 'center'}}>


           <Tooltip title="Question">
           <Flag onClick={() => {setSeeFlagOpen("Question")}}  style={{ color: 'red' }}  />
           </Tooltip>

           {seeFlagOpen =="Question" && <SeeFlagModal type = "Image" id={allImages[currentIndex].id} flag_type="Question" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
          <span> {allImages[currentIndex].numQFlags} </span>
          <Tooltip title="Comment">
          <Flag onClick={() => {setSeeFlagOpen("Comment")}}  style={{ color: 'orange' }}  />
          </Tooltip>
          {seeFlagOpen =="Comment" && <SeeFlagModal type = "Image" id={allImages[currentIndex].id} flag_type="Comment" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
          <span>  {allImages[currentIndex].numCFlags} </span>
          <Tooltip title="Verify">
          <Flag onClick={() => {setSeeFlagOpen("Verify")}} style={{ color: 'green' }}  />
          </Tooltip>
          {seeFlagOpen =="Verify" && <SeeFlagModal type = "Image" id={allImages[currentIndex].id} flag_type="Verify" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
          <span> {allImages[currentIndex].numVFlags}</span>
          <Tooltip title="Review">
          <Flag onClick={() => {setSeeFlagOpen("Review")}}  style={{ color: 'blue' }}  />
          </Tooltip>
          {seeFlagOpen =="Review" && <SeeFlagModal type = "Image" id={allImages[currentIndex].id} flag_type="Review" open={seeFlagOpen} setOpen={setSeeFlagOpen}/> }
          <span> {allImages[currentIndex].numRFlags}</span>
          <AddFlagModal imageType="plus" type="Image" entity={allImages[currentIndex]} />
          </Box>
  
  
  
        <Box sx={{bgcolor: 'background.paper', borderColor: 'text.primary', width: `${400 * dim[0]/dim[1]}px`, height: `400px`,
      marginTop: '30px', border: 1 }}>


            <img src={photo} alt="Image" style={{ width: '100%', height: '100%' }} />
        </Box>
  
        
        <Box sx={{width: '400px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
  
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> 
        <IconButton >
        <ThumbUp onClick={() => handleLike(allImages[currentIndex].id)} color={likedMap.has(String(allImages[currentIndex].id)) ? "primary" : "default"} style={{ fontSize: '23' }}/>
          </IconButton>
        <span style={{fontSize: '23'}}> {allImages[currentIndex].num_of_likes} </span>
          </Box>
  
                
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton onClick={handlePreviousImage}>
            <ArrowBackIosNewIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
          </IconButton>
          <span style={{ fontSize: '18px', margin: '0 10px' }}>{`${currentIndex + 1}/${allImages.length}`}</span>
          <IconButton onClick={handleNextImage}>
            <ArrowForwardIosIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
          </IconButton>
          </Box>
  
  
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> 
          {allImages.length > 0 && <CommentModal type="image" id={allImages[currentIndex].id} />} 
          </Box>
  
          
        
        </Box>
        </> }

            
        </Box>
  
        {allImages.length > 0 && <>
        <div style={{textAlign: 'left'}}> 
            <h3>Description: </h3>
            {allImages.length > 0 && <p>{allImages[currentIndex].description} </p>}
            <h3> Additional Info: </h3>
        </ div> 
  
  
  <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
   <TableBody>
   {rows.map((row) => (
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.statistic}</TableCell>
  
      </TableRow>
    ))}
  
   </TableBody>
  
   </Table>
    </TableContainer> 
    </>}
          </div> 
   
  
    );
  }

  export default ImageTab;