import React, {Component} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SelectPage from './pages/select/SelectPage';
import RegisterPage from './pages/register/RegisterPage';
import LoginPage from './pages/login/LoginPage';
import  ProfilePage from './pages/profile/ProfilePage';
import DiseasePage from './pages/disease/DiseasePage'
import FindingPage from './pages/finding/FindingPage';
import CommentsPage from './pages/comments/CommentsPage'
import { FilterProvider } from './contexts/FilterContext';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    this.setState({ hasError: true });

    alert('An error occurred: ' + error.message);
  }

  render() {
    return this.props.children;
  }
}


function App() {

  const [selectedEntity, setSelectedEntity] = React.useState([]);
  const [filterSelect, setFilterSelect] = React.useState([])

  return (
    <ErrorBoundary>
    <FilterProvider>
    <Router>
      <Routes> 
            <Route exact path="/" element={<SelectPage selectedEntity={selectedEntity} setSelectedEntity={setSelectedEntity} filterSelect={filterSelect} setFilterSelect={setFilterSelect}/>} />
            <Route exact path="/register" element={<RegisterPage />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/profile" element={<ProfilePage />} />
            <Route exact path="/disease" element={<DiseasePage selected={selectedEntity} setSelected={setSelectedEntity} filtered={filterSelect} setFiltered={setFilterSelect}/>} />
            <Route exact path="/finding" element={<FindingPage selected={selectedEntity} setSelected={setSelectedEntity} filtered={filterSelect} setFiltered={setFilterSelect}/>} />
            <Route exact path="/comments" element={<CommentsPage />} />
      </Routes>
    </Router>
    </FilterProvider>
    </ErrorBoundary>
  );
}
  


export default App;
