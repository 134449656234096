import React, { createContext, useState, useEffect } from "react";
import axios from 'axios';

export const FilterContext = createContext();

export const FilterProvider = ({children}) => {
    const [knowledgeFilter, setKnowledgeFilter] = useState(9);
    const [diseaseIds, setDiseaseIds] = useState([])
    const [findingIds, setFindingIds] = useState([])

    useEffect(() => {

        const fetchFilterIdsRefresh = async() => {
            let knowledgeThreshold = sessionStorage.getItem('knowledgeFilter');
            if(knowledgeThreshold) {
                fetchFilterIds(JSON.parse(knowledgeThreshold));
                return;
            }

            fetchFilterIds();

        }

        fetchFilterIdsRefresh();

    }, [])

    const fetchFilterIds = async (filterSelected = knowledgeFilter) => {
        const diseaseFilterIds = await axios.get(`https://api.clinicalphenomics.org/disease/getFilterIds/${filterSelected}`);
        const findingFilterIds = await axios.get(`https://api.clinicalphenomics.org/finding/getFilterIds/${filterSelected}`);


        sessionStorage.setItem('knowledgeFilter', JSON.stringify(filterSelected))


        setDiseaseIds(diseaseFilterIds.data);
        setFindingIds(findingFilterIds.data);
        setKnowledgeFilter(filterSelected);
        
    }

    return (
        <FilterContext.Provider value={{ fetchFilterIds, knowledgeFilter, diseaseIds, findingIds }}>
            {children}
        </FilterContext.Provider>
    );

}